// Importa useEffect y useLocation como antes
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Importa nprogress y su hoja de estilo
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    NProgress.start();

    const handleLoad = () => {
      NProgress.done();
      window.scrollTo(0, 0);
    };

    window.addEventListener('load', handleLoad);
    // Si la página ya está cargada, finaliza la barra de progreso y desplaza hacia arriba
    if (document.readyState === 'complete') {
      NProgress.done();
      window.scrollTo(0, 0);
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;
