/**
 * Footer
 * Componente Footer que renderiza el pie de página de la aplicación.
 * Incluye enlaces para navegación, información de contacto y enlaces legales.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import styles from "../css/Footer.module.css";
import labuimagen from "../img/logolabuU.svg";
import numeroimagen from "../img/telefono.svg";
import emailimagen from "../img/email.svg";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <hr />
      <div className={styles.links}>
        <img className={styles.LabU2} src={labuimagen} alt="Lab-U" />

        { /* 
        <div className={styles.navigationLinks}>
          <p>Lista de Exámenes</p>
          <ul>
            <li><a href="#">Hemograma</a></li>
            <li><a href="#">Descarte de VPH</a></li>
            <Link to="/lista_examenes" >Ver más..</Link>
          </ul>
          </div>
          */
        }

        <div className={styles.navigationLinks}>
          <p>Acerca de Nosotros</p>
          <ul>
            <li><Link to="/nosotros">¿Quiénes somos?</Link></li>
          </ul>
        </div>
        {
          /*
          <div className={styles.navigationLinks}>
          <p>Sedes</p>
          <ul>
            <li><a href="#" translate='no'>Ate</a></li>
            <li><a href="#">Callao</a></li>
            <li><a href="#">Comas</a></li>
            <Link to="/sede">Ver más..</Link>
          </ul>
          </div>
          */
        }
        <div className={styles.navigationLinks}>
          <p>Contacto</p>
          <ul>
            <li className={styles.contactListItem}>
              <img className={styles.LabU2} src={numeroimagen} alt="Lab-U" />
              <a href="tel:908815833">
                +51 908 815 833
              </a>
            </li>
            <li className={styles.contactListItem}>
              <img className={styles.LabU2} src={emailimagen} alt="Lab-U" />
              <a href="mailto:contacto@lab-u.pe">
                contacto@lab-u.pe
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.info2}>
          { 
          //<Link to="/terminos-y-condiciones">Términos y condiciones</Link>
          }
          {
          //<Link to="/politicas-de-privacidad">Políticas de privacidad</Link>
          }
          <Link to="/resultados">Resultados</Link>
        </div>
        <div className={styles.info3}>
          <p>©Lab-u - Todos los derechos reservados.</p></div>
      </div>
    </div >
  );
}

export default Footer;