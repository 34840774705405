import { Link, useParams } from "react-router-dom";

/** Datos temporales, remplazar con API*/
const tempResultsData = {
    "240400000000191e5f9ca": {
        fullname: "NAOMI MEDINA REYES",
        idcardCategory: "CE",
        idcard: "30568809",
        examenName: "PRUEBA DE GENOTIPIFICACIÓN DEL TIPO 16 Y 18",
        emited: "26/04/2024",
        healthProffessional: {
            fullname: "DR. VICTOR F. SAN MARTIN CHIPOCO",
            especialitation: "PATOLOGO CLÍNICO",
            cmp: "22836"
        }
    },
    "240400000000391e5f9cb": {
        fullname: "REYNA CASTRO CRUZ",
        idcardCategory: "DNI",
        idcard: "76354452	",
        examenName: "PRUEBA DE GENOTIPIFICACIÓN DEL TIPO 16 Y 18",
        emited: "02/05/2024",
        healthProffessional: {
            fullname: "DR. VICTOR F. SAN MARTIN CHIPOCO",
            especialitation: "PATOLOGO CLÍNICO",
            cmp: "22836"
        }
    },
    "240400000000291e5f9cb": {
        fullname: "USCA TARCO ANDREA",
        idcardCategory: "DNI",
        idcard: "47934845",
        examenName: "PRUEBA DE GENOTIPIFICACIÓN DEL TIPO 16 Y 18",
        emited: "02/05/2024",
        healthProffessional: {
            fullname: "DR. VICTOR F. SAN MARTIN CHIPOCO",
            especialitation: "PATOLOGO CLÍNICO",
            cmp: "22836"
        }
    },
    "": {
        fullname: "",
        idcardCategory: "",
        idcard: "",
        examenName: "",
        emited: "",
        healthProffessional: {
            fullname: "DR. VICTOR F. SAN MARTIN CHIPOCO",
            especialitation: "PATOLOGO CLÍNICO",
            cmp: "22836"
        }
    }
}

var validated = false;

function validateCode(validationCode) {
    if (tempResultsData[validationCode])
        validated = true
}

export default function Validation() {
    const { validationCode } = useParams();
    validateCode(validationCode)
    return (
        <div className="results-validation m-auto">
            <div className="p-4">
                <div className="text-center">
                    <h1>Validación de Resultados</h1>
                </div>
                {validated ? (
                    <div className="validated-info">
                        <div className="validated bg-green-200 text-center rounded text-lg text-green-800">
                            El éxamen ES VÁLIDO
                        </div>
                        <div className="title uppercase text-xs font-bold bg-gray-200 text-gray-600 px-2 py-1 rounded mt-3">Paciente</div>
                        <div className="md:flex mt-2">
                            <div className="row md:mt-0 md:flex-1">
                                <div className="text-xs font-bold">Apellidos y Nombres</div>
                                <div className="text-xl">{tempResultsData[validationCode].fullname}</div>
                            </div>
                            <div className="row md:flex-1">
                                <div className="text-xs font-bold">Documento de Identidad</div>
                                <div className="text-xl">{tempResultsData[validationCode].idcardCategory}: {tempResultsData[validationCode].idcard}</div>
                            </div>
                        </div>
                        <div className="title uppercase text-xs font-bold bg-gray-200 text-gray-600 px-2 py-1 rounded mt-3">Profesional de la Salud</div>
                        <div className="md:flex mt-2">
                            <div className="row md:mt-0 md:flex-1">
                                <div className="text-xs font-bold">Apellidos y Nombres</div>
                                <div className="text-xl">{tempResultsData[validationCode].healthProffessional.fullname}</div>
                            </div>
                            <div className="row md:flex-1">
                                <div className="text-xs font-bold">Especialidad</div>
                                <div className="text-xl">{tempResultsData[validationCode].healthProffessional.especialitation}</div>
                            </div>
                            <div className="row md:flex-1">
                                <div className="text-xs font-bold">C.M.P</div>
                                <div className="text-xl">{tempResultsData[validationCode].healthProffessional.cmp}</div>
                            </div>
                        </div>
                        <div className="title uppercase text-xs font-bold bg-gray-200 text-gray-600 px-2 py-1 rounded mt-3">Detalles del Exámen</div>
                        <div className="row md:flex-1 mt-2">
                            <div className="text-xs font-bold">Tipo de Exámen</div>
                            <div className="text-xl">{tempResultsData[validationCode].examenName}</div>
                        </div>
                        <div className="md:flex">
                            <div className="row md:mt-0 md:flex-1">
                                <div className="text-xs font-bold">Fecha de Emisión</div>
                                <div className="text-xl">{tempResultsData[validationCode].emited}</div>
                            </div>
                            <div className="row md:flex-1">
                                <div className="text-xs font-bold">Código Verificador</div>
                                <div className="text-xl">{validationCode}</div>
                            </div>
                        </div>
                        {
                            /*
                                <Link className="bg-red-100 py-2 px-4 max-w-60 text-center rounded mt-4 block text-red-700 no-underline m-auto hover:bg-red-700 hover:text-white" to="/resultados">Ver más detalles</Link>
                            */
                        }
                    </div>
                ) : (
                    <div className="invalid-data">
                        <div className="validated bg-red-200 text-center rounded text-lg text-red-800">
                            El éxamen <b>NO ÉXISTE EN NUESTRAS BASES DE DATOS</b>
                        </div>
                        <p className="text-sm">Lo sentimos, pero no hemos encontrado resultados en nuestra base de datos con el código de validación proporcionado. Para evitar posibles fraudes, te recomendamos verificar que el código sea correcto y volver a intentarlo. Si crees que esto es un error, por favor contáctanos para que podamos ayudarte.</p>
                        <div className="p-5"></div>
                    </div>
                )}
            </div>
        </div>
    );
}