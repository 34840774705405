import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import styles from "../../css/Table.module.css";
import izquierda from "../../img/iconizquierda.svg";
import derecha from "../../img/iconderecha.svg";
import AxiosServices from "../../AuthUser";
import '../../css/buttonCerrar.css';

const Table = () => {


  const { http, deleteToken } = AxiosServices()
  // Datos de ejemplo para la paginación
  const navigate = useNavigate();
  const [atenciones, setAtenciones] = useState([]);
  const [atencion, setAtencion] = useState([]);
  const [persona, setPersona] = useState("");

  let paciente_id = JSON.parse(localStorage.getItem("paciente_id"));

  const getResultados = async () => {
    http
      .get(`paciente/laboratorio/resultados/${paciente_id}`)
      .then((response) => {
        //console.log(response);
        if (response.data.data) {
          setPersona(response?.data?.data?.persona);
          const data = response?.data?.data?.resultados.map(data => data.lab_tipos);
          console.log("data", data);

          if (data.length > 0) {
            let resultado = [].concat.apply([], data)
            setAtenciones(resultado);
          } else {
            setAtenciones([])
          }
        }

      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClose = () => {
    deleteToken();
    let url = "/resultados";
    //console.log(url);
    navigate(url);
  };

  useEffect(() => {
    getResultados();
  }, []);

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * itemsPerPage;
  const slicedData =
    atenciones && atenciones.slice(offset, offset + itemsPerPage);

  const [visible, setVisible] = useState(false);
  // console.log(slicedData);
  return (
    <div className={styles.conteiner}>
      <div className={styles.usuario}>
        <p className={styles.text1}>Bienvenido(a)</p>
        <p className={styles.text2}>{persona?.nombre_completo}</p>
      </div>


      <div className={`p-4 mt-5 ${styles.table}`}>
        <p className={styles.text}>Mis resultados</p>
        <div className="table-container">

          <table className="mt-4">
            <thead>
              <tr>
                <th>Nombre de Análisis</th>
                <th>Fecha</th>
                <th>Estado</th>
                <th>Resultado</th>
              </tr>
            </thead>
            <tbody>
              {atenciones && atenciones.length > 0 ? (
                atenciones.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.lab_pruebas[0]?.examen?.nombre}</td>
                    <td>{new Date(item?.lab_pruebas[0]?.fecha_registro).toLocaleDateString()}</td>
                    <td>{item?.lab_pruebas[0]?.archivo ? "Procesado" : "En proceso"}</td>
                    <td>
                      <Button
                        className="pdf-btn"
                        icon="pi pi-file-pdf"
                        text
                        severity="danger"
                        aria-label="FilePDF"
                        onClick={() => {
                          setAtencion(item);
                          setVisible(true);
                        }}
                        disabled={item?.lab_pruebas[0]?.archivo ? false : true}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No hay datos disponibles
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Dialog
          visible={visible}
          style={{ width: "80vw", height: "120vh" }}
          onHide={() => setVisible(false)}
          contentStyle={{ overflow: "hidden" }}
        >
          <h2 className="plResultados">Resultados</h2>
          {(() => {
            try {
              if (atencion?.lab_pruebas && atencion.lab_pruebas.length > 0 && atencion.lab_pruebas[0]?.archivo) {
                return (
                  <iframe
                    title="PDF"
                    style={{ width: "100%", height: "80%", border: "none" }}
                    src={atencion.lab_pruebas[0].archivo}
                  />
                );
              } else {
                throw new Error("No hay resultados disponibles");
              }
            } catch (error) {
              console.error("Error al cargar los resultados:", error);
              return <p>No hay resultados disponibles</p>;
            }
          })()}
          <div className="ContainerCerrarPedf">
            <a className="CerrarPdf" onClick={() => setVisible(false)}>Cerrar</a>
          </div>
        </Dialog>

        {/* 
        <div className={styles.paginationContainer}>
          <div>
            <span
              onClick={() =>
                setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))
              }
            >
              {<img src={izquierda} alt="Previous" />}
            </span>
            <input
              type="text"
              value={currentPage + 1}
              readOnly
              onChange={(e) => {
                const inputValue = parseInt(e.target.value, 10);
                if (!isNaN(inputValue)) {
                  setCurrentPage(
                    Math.min(
                      Math.max(inputValue - 1, 0),
                      Math.ceil(atenciones.length / itemsPerPage) - 1
                    )
                  );
                }
              }}
              min={1}
              max={atenciones ? Math.ceil(atenciones.length / itemsPerPage) : 1}
              className={styles.pageNumberInput} // Nuevo estilo para desactivar las flechas de incremento y decremento
            />
            <span
              onClick={() =>
                setCurrentPage((prevPage) =>
                  Math.min(
                    prevPage + 1,
                    Math.ceil(atenciones.length / itemsPerPage) - 1
                  )
                )
              }
            >
              {<img src={derecha} alt="Next" />}
            </span>
          </div>
        </div> */}
        <div className="card flex flex-wrap justify-content-center gap-1">
          <a className="custom-close-button" onClick={handleClose}>Cerrar</a>
        </div>
      </div>
    </div>
  );
};

export default Table;
